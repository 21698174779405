'use strict';
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

//----------------------------------------------------
// Components: Nav
//----------------------------------------------------

let navButton = document.querySelector('.js-nav-toggle');

navButton.addEventListener('click', () => {
  document.querySelector('html').classList.toggle('is-active');
});

//----------------------------------------------------
// Components: text Background
//----------------------------------------------------
ScrollTrigger.create(
  {
    trigger: ".section.is-about",
    start: "top 90%",
    toggleClass: {targets: ".textBg__wrapper",className: "is-active"},
    once: true,
  }
)

//----------------------------------------------------
// Components: media
//----------------------------------------------------
// カードがスクロールに合わせて出現
document.querySelectorAll(".js-fadeIn").forEach((el) => {
  gsap.fromTo(
      el,
      { y: 50, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 0.5,
        // スクロールトリガーの登録
        scrollTrigger: {
          trigger: el,
          start: "top 90%",
          ease: "expo",
        },
      }
  );
});

